import React from 'react';
import { Container, Row, Col } from 'bootstrap-4-react';
import styles from './SectionStory.module.css';
import picture1 from '../../media/img/story-img1.png';
import picture2 from '../../media/img/story-img2.png';
import leftarrow from '../../media/img/left-arrow.svg';
import rightarrow from '../../media/img/right-arrow.svg';
import classNames from 'classnames';

const SwapCardsBurn = () => {
    return (
        <section className={styles.SectionStoryBurn} style={{ marginBottom: "10px" }}>
            <Container style={{
                display: "flex",
                justifyContent: "center",
            }}>
                <Col col="lg-4 sm-12 md-6">
                    <Row className={styles.pictures} style={{
                        width: "auto",
                        maxWidth: "auto",
                    }}>
                        <video autoPlay={true} muted loop width={"100%"}>
                            <source src="/burn_video.mp4" type="video/mp4" />
                        </video>
                    </Row>
                </Col>
            </Container>
        </section>
    );
}

export default SwapCardsBurn;
