/* eslint-disable no-console */
import * as anchor from '@project-serum/anchor';
import {
    createAssociatedTokenAccountInstruction,
    createInitializeMintInstruction,
    createMintToInstruction,
    getMint,
    MintLayout,
    TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { AnchorWallet } from '@solana/wallet-adapter-react';
import { Connection, Keypair, LAMPORTS_PER_SOL, PublicKey, Transaction } from '@solana/web3.js';
import { ComputeBudgetProgram } from "@solana/web3.js";

export const PRIORITY_FEE_IX = ComputeBudgetProgram.setComputeUnitPrice({microLamports: 100000});

const RPC = process.env.REACT_APP_SOLANA_RPC_HOST || '';

export const getSOLBalance = async (
    connection: anchor.web3.Connection,
    pubkey: anchor.web3.PublicKey,
    verbose = true
) => {
    const walletAccount = await connection.getAccountInfo(pubkey);
    const balance = walletAccount?.lamports || 0; // In case wallet is not current initiated, walletAccount will be undefined
    const sol = balance / LAMPORTS_PER_SOL;
    if (verbose) {
        console.log(`${pubkey.toBase58()} has ${sol} SOLs`);
    }
    return sol;
};

export const getTokenBalance = async (
    connection: anchor.web3.Connection,
    pubkey: anchor.web3.PublicKey,
    verbose = true
) => {
    const token = await connection.getTokenAccountBalance(pubkey);
    const tokenAmount = token.value.uiAmount;
    if (verbose) {
        console.log(`${pubkey.toBase58()} has ${tokenAmount} Tokens`);
    }
    return tokenAmount;
};

export const fastConnection = new anchor.web3.Connection(RPC, {
    confirmTransactionInitialTimeout: 120 * 1000, // 60 Seconds
    commitment: 'processed',
});

export const slowConnection = new anchor.web3.Connection(RPC, {
    confirmTransactionInitialTimeout: 120 * 1000, // 120 Seconds
    commitment: 'confirmed',
});

export const getMintDetails = async (mint: anchor.web3.PublicKey) => {
    console.log({ fastConnection, mint });
    return await getMint(fastConnection, mint);
};

export const createToken = async (
    wallet: AnchorWallet,
    connection: Connection,
    decimals = 9,
    quantity: number = 1000000 * 10 ** 9
): Promise<PublicKey> => {
    const mint = anchor.web3.Keypair.generate();
    const userTokenAccountAddress = (await getAtaForMint(mint.publicKey, wallet.publicKey))[0];
    const provider = createProvider(connection, wallet);
    const transaction = new Transaction();
    transaction.add(PRIORITY_FEE_IX);
    transaction.add(
        anchor.web3.SystemProgram.createAccount({
            fromPubkey: wallet.publicKey,
            newAccountPubkey: mint.publicKey,
            space: MintLayout.span,
            lamports: await connection.getMinimumBalanceForRentExemption(MintLayout.span),
            programId: TOKEN_PROGRAM_ID,
        })
    );
    transaction.add(
        createInitializeMintInstruction(mint.publicKey, decimals, wallet.publicKey, wallet.publicKey, TOKEN_PROGRAM_ID)
    );
    transaction.add(
        createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            userTokenAccountAddress,
            wallet.publicKey,
            mint.publicKey
        )
    );
    transaction.add(createMintToInstruction(mint.publicKey, userTokenAccountAddress, wallet.publicKey, quantity));
    const tx = await provider.send(transaction, [mint]);
    await provider.connection.confirmTransaction(tx, 'confirmed');
    console.log(`Created token ${tx}`);
    return mint.publicKey;
};

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new anchor.web3.PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
);

export const getAtaForMint = async (
    mint: anchor.web3.PublicKey,
    buyer: anchor.web3.PublicKey
): Promise<[anchor.web3.PublicKey, number]> => {
    return await anchor.web3.PublicKey.findProgramAddress(
        [buyer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );
};

export const createProvider = (connection: anchor.web3.Connection, wallet: AnchorWallet) => {
    const provider = new anchor.Provider(connection, wallet, {
        // preflightCommitment: "recent",
        skipPreflight: true,
        commitment: 'confirmed',
    });
    return provider;
};

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const sleepTillEpoch = async (epoch: number, verbose = false) => {
    const secondsToSleep = epoch - Date.now() / 1000;
    if (secondsToSleep > 0) {
        if (verbose) {
            console.log(`Sleeping for ${secondsToSleep} seconds`);
        }
        await sleep(secondsToSleep * 1000);
    }
};

// Empty wallet
const emptyKeypair = Keypair.generate();
export const emptyAnchorWallet: AnchorWallet = {
    signTransaction: () => {
        return undefined;
    },
    signAllTransactions: () => {
        return undefined;
    },
    publicKey: emptyKeypair.publicKey,
};

// Sending transaction

export const sendAndConfirmTransactionWithRetries = async (connection: Connection, transaction: Transaction) => {
    let txSign = undefined;
    for (let i = 0; i <= 20; i++) {
      try {
        txSign = await connection.sendRawTransaction(transaction.serialize(), {});
        console.log(`Transaction sent(${i}): ${txSign}`);
        const result = await connection.confirmTransaction(txSign, 'finalized');
        console.log({ result });
        break;
      } catch (error: any) {
        const errorMessage = error?.message;
        if (errorMessage) {
          if (errorMessage.includes('This transaction has already been processed')) {
            if (txSign) await slowConnection.confirmTransaction(txSign, 'finalized');
            break;
          }
          if (errorMessage.includes('Blockhash not found')) {
            throw error;
          }
        }
        console.log({ error, errorMessage: error?.message });
        if (i === 20) {
          throw error;
        }
      }
    }
  };
  
  export const sendAndConfirmTransactionListCustom1 = async (
    wallet: AnchorWallet,
    connection: anchor.web3.Connection,
    transactionList: Transaction[],
  ) => {
    const recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
    const transactionWithBlockhashList = transactionList.map(transaction => {
      transaction.recentBlockhash = recentBlockhash;
      transaction.feePayer = wallet.publicKey;
      return transaction;
    });
    if (wallet.signAllTransactions) {
      const signedTransactionList = await wallet.signAllTransactions(transactionWithBlockhashList);
      const sendPromises = signedTransactionList.map(
        async transaction => await sendAndConfirmTransactionWithRetries(connection, transaction),
      );
      await Promise.all(sendPromises);
    } else {
      for (const transaction of transactionWithBlockhashList) {
        const signedTransaction = await wallet.signTransaction(transaction);
        await sendAndConfirmTransactionWithRetries(connection, signedTransaction);
      }
    }
  };