import { CardActionArea, Modal,Button,Box, Grid, } from "@mui/material";
import Masonry from '@mui/lab/Masonry';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useWallet } from "@solana/wallet-adapter-react";
import { StringInputType1, SubmitButtonType1, CountryDropDownInputType } from "components/Form/inputs";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import * as React from "react";
import { fastConnection, PRIORITY_FEE_IX } from "solanacodes/utils";
import { countryList } from "solanacodes/constants";
import * as Yup from "yup";
import './style.css'; 
import { useContractStore } from "contexts/ContractStoreProvider";
import { getBurnMintAnimation } from "solanacodes/nftutils";
import { web3 } from "@project-serum/anchor";
import { PublicKey, sendAndConfirmTransaction } from "@solana/web3.js";
import base58 from "bs58";
import nacl from 'tweetnacl';

interface AddressType {
  name: string;
  surname: string;
  email: string;
  phone: string;
  discordProfile?: string;
  street: string;
  street2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export const BurnedNftCard = ({ name, image, mint }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { signMessage, publicKey, signTransaction } = useWallet();
  const [open, setOpen] = React.useState(false);
  const [loadModal, setLoadModal] = React.useState(false);
  const { justBurnedNft, setJustBurnedNft } = useContractStore();
  const [mintOpen, setMintOpen] = React.useState(false);
  const [mintSku, setMintSku] = React.useState("");

  const handleClose = () => {
    if (mintOpen) {
      setOpen(false);
      setMintOpen(false);
    }
  }

  React.useEffect(() => {
    if (justBurnedNft === mint) {
      setLoadModal(true);
      setOpen(true);
      setJustBurnedNft("");
    }
  }, [justBurnedNft, mint, setJustBurnedNft]);

  const [fetchedAddress, setFetchedAddress] = React.useState<
    undefined | AddressType
  >(undefined);

  const setFetchedAddressState = (value)=>{
    setFetchedAddress(value)
    setLoadModal(true)
  }
  const fetchAddressHandler = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async () => {
      const connection = fastConnection;
      try {
        enqueueSnackbar(`Please sign message for verification...`, {
          variant: "info",
        });
        enqueueSnackbar(`Fetching address...`, { variant: "info" });

        const message = new TextEncoder().encode('Signing this messages verifies you own the wallet');
        const uint8arraySignature = await signMessage(message);
        const res = await fetch(`${process.env.REACT_APP_ADMIN_URL}/api/general/get`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            publicKey: publicKey,
            signature: base58.encode(uint8arraySignature),
            network: process.env.REACT_APP_SOLANA_NETWORK,
            mint,
          }),
        });
        const resJson = await res.json();
        console.log(`my mint`, mint);
        if (resJson.success) {
          enqueueSnackbar(`${resJson.message}`, { variant: "success" });
          setFetchedAddressState(resJson.data.address || "");
          setMintSku(resJson.data.sku|| "");
        } else {
          enqueueSnackbar(`Error: ${resJson.message}`, { variant: "error" });
        }
       
        // const latestSlotId = await connection.getSlot();
        // const latestBlockDetails = await connection.getBlock(latestSlotId, { maxSupportedTransactionVersion: 0 });
        
        // let memoTx = new web3.Transaction();
        // memoTx.add(PRIORITY_FEE_IX);
        
        // let nonce = `Sign: ${latestBlockDetails?.blockhash}`;
        // memoTx.add(
        //   new web3.TransactionInstruction({
        //     keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
        //     data: Buffer.from(nonce, "utf-8"),
        //     programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
        //   })
        // )

        // memoTx.feePayer = publicKey;
        // memoTx.recentBlockhash = latestBlockDetails.blockhash;
        // let transaction;
        // try {
        //   const txn = await signTransaction(memoTx);
        //   enqueueSnackbar(`Signing message...`, { variant: "info", autoHideDuration: 3000 });
        //   transaction = await connection.sendRawTransaction(txn.serialize(), {
        //     skipPreflight: true,
        //     maxRetries: 100,
        //   });
        //   await connection.confirmTransaction(transaction, "processed");
        // } catch (e) {
        //   alert("Something went wrong in signing");
        // }
        
        // const pub = publicKey?.toBase58();
        
        // const res = await fetch(`${process.env.REACT_APP_ADMIN_URL}/api/general/get`, {
        //   method: "POST",
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     slotId: latestSlotId,
        //     transaction,
        //     nonce,
        //     publicKey: pub,
        //     network: process.env.REACT_APP_SOLANA_NETWORK,
        //     mint,
        //   }),
        // });
        // const resJson = await res.json();
        // console.log(`my mint`, mint);
        // if (resJson.success) {
        //   enqueueSnackbar(`${resJson.message}`, { variant: "success" });
        //   setFetchedAddressState(resJson.data.address || "");
        //   setMintSku(resJson.data.sku|| "");
        // } else {
        //   enqueueSnackbar(`Error: ${resJson.message}`, { variant: "error" });
        // }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        enqueueSnackbar(`Error: ${error?.message}`, { variant: "error" });
      }
    },
    [enqueueSnackbar, signMessage, publicKey, mint]
  );

  const submitAddressHandler = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (values: any) => {
        const connection = fastConnection;
        try {
            enqueueSnackbar(`Please sign message for verification...`, {
                variant: 'info',
            });
            enqueueSnackbar(`Updating address...`, { variant: 'info' });
          //   const latestSlotId = await connection.getSlot();
          //   const latestBlockDetails = await connection.getBlock(latestSlotId, { maxSupportedTransactionVersion: 0 });
           
          //   let memoTx = new web3.Transaction();
          //   let nonce = `Sign: ${latestBlockDetails?.blockhash}`;
          //   await memoTx.add(
          //     new web3.TransactionInstruction({
          //       keys: [{ pubkey: publicKey, isSigner: true, isWritable: true }],
          //       data: Buffer.from(nonce, "utf-8"),
          //       programId: new web3.PublicKey("MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"),
          //     })
          //   )

          //   memoTx.feePayer = publicKey;
          //   memoTx.recentBlockhash = latestBlockDetails.blockhash;
          //   let transaction;
          //   try {
          //     const txn = await signTransaction(memoTx);
          //     enqueueSnackbar(`Signing message...`, { variant: "info", autoHideDuration: 3000 });

          //     transaction = await connection.sendRawTransaction(txn.serialize(), {
          //       skipPreflight: true,
          //       maxRetries: 100,
          //     });
          // const confirm = await connection.confirmTransaction(transaction, "finalized");

          //   } catch (e) {
          //     alert("Something went wrong in signing");
          //   }
              
          //   console.log(values);
                
          const message = new TextEncoder().encode('Signing this messages verifies you own the wallet');
          const uint8arraySignature = await signMessage(message);
          const res = await fetch(`${process.env.REACT_APP_ADMIN_URL}/api/general/submit`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              publicKey: publicKey,
              signature: base58.encode(uint8arraySignature),
              network: process.env.REACT_APP_SOLANA_NETWORK,
              mint,
                    address: {
                        name: values.name,
                        surname: values.surname,
                        email: values.email,
                        phone: values.phone,
                        discordProfile: values.discordProfile,
                        street: values.street,
                        street2: values.street2,
                        city: values.city,
                        state: values.state,
                        postcode: values.postcode,
                        country: values.country,
                    },
            }),
          });
          const resJson = await res.json();
          console.log(`my mint`, mint);
          console.log(`resJson`, resJson);
          if (resJson.success) {
            enqueueSnackbar(`${resJson.message}`, { variant: "success" });
            setFetchedAddressState(resJson.data?.address || "");
            setMintSku(resJson.data?.sku|| "");
          } else {
            enqueueSnackbar(`Error: ${resJson.message}`, { variant: "error" });
          }
       
          
            // const pub = publicKey?.toBase58();
            // // const res = await fetch(`https://burn-and-address-admin.vercel.app/api/general/submit`, {
            //   const res = await fetch(`${process.env.REACT_APP_ADMIN_URL}/api/general/submit`, {
            // method: 'POST',
            //     headers: {
            //         Accept: 'application/json',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         slotId: latestSlotId,
            //         transaction,
            //         nonce,
            //         publicKey: pub,
            //         network: process.env.REACT_APP_SOLANA_NETWORK,
            //         mint,
            //         address: {
            //             name: values.name,
            //             surname: values.surname,
            //             email: values.email,
            //             phone: values.phone,
            //             discordProfile: values.discordProfile,
            //             street: values.street,
            //             street2: values.street2,
            //             city: values.city,
            //             state: values.state,
            //             postcode: values.postcode,
            //             country: values.country,
            //         },
            //     }),
            // });
            // const resJson = await res.json();
            // console.log(mint);
            // if (resJson.success) {
            //     enqueueSnackbar(`${resJson.message}`, { variant: 'success' });
            // } else {
            //     enqueueSnackbar(`Error: ${resJson.message}`, { variant: 'error' });
            // }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.log(error);
            enqueueSnackbar(`Error: ${error?.message}`, { variant: 'error' });
        }
    },
    [enqueueSnackbar, signMessage, publicKey, mint]
);

  return (
    <Card className="mintCard">
      <CardActionArea>
        <CardMedia component="img" image={image} alt="nft" />
        <CardContent className="cardContent">
          <Typography className="mintAddy" gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          
           <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              await fetchAddressHandler()
              setSubmitting(false);
              setOpen(true);
            }}
          >
           {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Button type="submit" className="mintBtn" variant="contained" >
            Address Details
           </Button>
              </form>
            )}
          </Formik>
        </CardContent>
      </CardActionArea>
      {loadModal? <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  
>
<Box className="masonry" sx={style}>

  <Grid container spacing={4}>
    <Grid item xs={4}>
      <Typography fontSize={22} pb={1}>Proof of Ownership Token</Typography>
      <img className="poo-img" src={getBurnMintAnimation(mint)} />
      <Typography fontSize={16} pt={2}>This token grants access to the Toonies community and all holder perks.</Typography>
    </Grid>
    <Grid item xs={8}>
           <Formik
            initialValues={{
              name: fetchedAddress?.name || "",
              surname: fetchedAddress?.surname || "",
              email: fetchedAddress?.email || "",
              phone: fetchedAddress?.phone || "",
              discordProfile: fetchedAddress?.discordProfile || "",
              street: fetchedAddress?.street || "",
              street2: fetchedAddress?.street2 || "",
              city: fetchedAddress?.city || "",
              state: fetchedAddress?.state || "",
              postcode: fetchedAddress?.postcode || "",
              country: fetchedAddress?.country || "",
            }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              await submitAddressHandler(values);
              setSubmitting(false);
              setOpen(false);
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              surname: Yup.string().required("Required"),
              email: Yup.string().email("Invalid email").required("Required"),
              phone: Yup.string(),
              discordProfile: Yup.string(),
              street: Yup.string().required("Required"),
              street2: Yup.string(),
              city: Yup.string().required("Required"),
              state: Yup.string().required("Required"),
              postcode: Yup.string().required("Required"),
              country: Yup.string().required("Required"),
            })}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Masonry columns={2} spacing={2}>
                <StringInputType1 name="name" label="Name" type="text" />
                <StringInputType1 name="surname" label="Surname" type="text" />
                <StringInputType1 name="email" label="Email" type="email" />
                <StringInputType1 name="phone" label="Phone" type="text" />
                <StringInputType1 name="discordProfile" label="Discord Profile" type="text" />
                <StringInputType1 name="street" label="Address 1" type="text" />
                <StringInputType1 name="street2" label="Address 2" type="text" />
                <StringInputType1 name="city" label="City" type="text" />
                <StringInputType1 name="state" label="State" type="text" />
                <StringInputType1 name="postcode" label="Postcode" type="text" />
                <CountryDropDownInputType name="country" label="Country" options={countryList} />
                </Masonry>
                <SubmitButtonType1 label="Update Address" />
              </form>
            )}
          </Formik>

          </Grid>
  </Grid>
          </Box>
          </Modal>:""}
    </Card>
  );
};
