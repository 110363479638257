import { Connection, PublicKey } from '@solana/web3.js';
import bs58 from 'bs58';
import { decodeMetadata, getMetadataAccount } from './metadata.service';
import { nftData, mintAnimationMap } from './constants';

const TOKEN_METADATA_PROGRAM = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

const MAX_NAME_LENGTH = 32;
const MAX_URI_LENGTH = 200;
const MAX_SYMBOL_LENGTH = 10;
const MAX_CREATOR_LEN = 32 + 1 + 1;
const MAX_CREATOR_LIMIT = 5;
const MAX_DATA_SIZE =
    4 + MAX_NAME_LENGTH + 4 + MAX_SYMBOL_LENGTH + 4 + MAX_URI_LENGTH + 2 + 1 + 4 + MAX_CREATOR_LIMIT * MAX_CREATOR_LEN;
const MAX_METADATA_LEN = 1 + 32 + 32 + MAX_DATA_SIZE + 1 + 1 + 9 + 172;
const CREATOR_ARRAY_START = 1 + 32 + 32 + 4 + MAX_NAME_LENGTH + 4 + MAX_URI_LENGTH + 4 + MAX_SYMBOL_LENGTH + 2 + 1 + 4;

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

export const getMetadata = async (mint: PublicKey): Promise<PublicKey> => {
    return (
        await PublicKey.findProgramAddress(
            [Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer()],
            TOKEN_METADATA_PROGRAM_ID
        )
    )[0];
};

export const getMetadataFromTheMint = async (connection: Connection, mint: string) => {
    const metadataAccount = await getMetadataAccount(mint);
    const metadataAccountData = await connection.getAccountInfo(new PublicKey(metadataAccount));
    if (!metadataAccountData) {
        return undefined;
    }
    const metadataDecoded = await decodeMetadata(metadataAccountData.data);
    if (!metadataDecoded) {
        return undefined;
    }
    console.log(`data uri`, metadataDecoded.data.uri)
    const res = await fetch(metadataDecoded.data.uri);
    const fullMetadata = await res.json();
    return { onChainMetadata: metadataDecoded, fullMetadata, mint: mint };
};

export const getMintAddresses = async (connection: Connection, firstCreatorAddress: PublicKey) => {
    const metadataAccounts = await connection.getProgramAccounts(TOKEN_METADATA_PROGRAM, {
        // The mint address is located at byte 33 and lasts for 32 bytes.
        dataSlice: { offset: 33, length: 32 },

        filters: [
            // Only get Metadata accounts.
            { dataSize: MAX_METADATA_LEN },

            // Filter using the first creator.
            {
                memcmp: {
                    offset: CREATOR_ARRAY_START,
                    bytes: firstCreatorAddress.toBase58(),
                },
            },
        ],
    });

    const mintList = metadataAccounts.map((metadataAccountInfo) => bs58.encode(metadataAccountInfo.account.data));

    const mintMetadataListPromises = mintList.map((mint) => getMetadataFromTheMint(connection, mint));

    const mintMetadataList = await Promise.all(mintMetadataListPromises);

    const filteredNftInfoList = mintMetadataList.filter((nftData) => {
        if (!nftData) {
            return false;
        }
        if (nftData.onChainMetadata.data.creators[0].verified) {
            return true;
        }
        return false;
    });

    return filteredNftInfoList;
};

export const getBurnMintAnimation = (mint) => {
    const nftType = nftData[mint];

    switch (nftType) {
        case 1: 
            return mintAnimationMap['heart'];
        case 2: 
            return mintAnimationMap['sun'];
        case 3: 
            return mintAnimationMap['bear'];
        case 4: 
            return mintAnimationMap['flower'];
        case 5: 
            return mintAnimationMap['alien'];
        case 6: 
            return mintAnimationMap['oneofone'];
        case 7: 
            return mintAnimationMap['heart_oneofone'];
        case 8: 
            return mintAnimationMap['sun_oneofone'];
        case 9: 
            return mintAnimationMap['bear_oneofone'];
        case 10: 
            return mintAnimationMap['flower_oneofone'];
        case 11: 
            return mintAnimationMap['alien_oneofone'];
    }
}