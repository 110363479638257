import { CardActionArea,Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { SubmitButtonType1 } from "components/Form/inputs";
import { useContractStore } from "contexts/ContractStoreProvider";
import { Formik } from "formik";
import * as React from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as anchor from "@project-serum/anchor";

import './style.css'; 
import { findTraitType } from "solanacodes/game/game";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const NftCard = ({ name, image, mint, nft }) => {
  const { onClickHandleBurn, getAllNfts, supplyList } = useContractStore();
  const [burnModalOpen, setBurnModalOpen] = React.useState(false);
  const [availableSupply, setAvailableSupply] = React.useState(0);

  console.log(supplyList);
  const handleClose = () => {
    return;
  }

  React.useEffect(() => {
    if (nft.traitType < 100 && supplyList[nft.traitType].toNumber()) {
      setAvailableSupply(supplyList[nft.traitType].toNumber());
    } else {
      setAvailableSupply(100);
    }
  }, [])
  return (
    <>
    <Card className="mintCard">
      <CardActionArea>
        <CardMedia component="img" image={image} alt="nft" />
        <CardContent className="cardContent">
          <Typography className="mintAddyHeader" gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              await onClickHandleBurn(mint, setBurnModalOpen);
              await getAllNfts();
              setSubmitting(false);
            }}
          >
             {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* <Button type="submit" disabled={!nft.isOneOfOne && availableSupply < 1} className="mintBtn"  variant="contained">
                  Burn
           </Button> */}
              </form>
            )}
          </Formik>
        </CardContent>
      </CardActionArea>
    </Card>
    <Modal
  open={burnModalOpen}
  onClose={handleClose}
    >
      <Box sx={style}>
        <div className="subtitle">Burning your Toonie NFT!</div>
        <Box mt={2}>
          <video src="/BurnAnimation1.mp4" width={250} autoPlay={true} loop={true} />
        </Box>
      </Box>
  </Modal>
    </>

  );
};
