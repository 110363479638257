import * as anchor from '@project-serum/anchor';
import { 
    ASSOCIATED_TOKEN_PROGRAM_ID, 
    getAssociatedTokenAddress, 
    TOKEN_PROGRAM_ID, 
    MINT_SIZE,
    createInitializeMintInstruction,
    createAssociatedTokenAccountInstruction,
    createMintToInstruction,
} from '@solana/spl-token';
import { AnchorWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, SYSVAR_CLOCK_PUBKEY, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import { getMetadataFromTheMint } from 'solanacodes/nftutils';
import { fastConnection, PRIORITY_FEE_IX, sendAndConfirmTransactionListCustom1 } from 'solanacodes/utils';
import { Game, IDL } from './idl';

export const CONTRACT_ID = 'BekWABn6kZisSLRpqUAcrfxD1w9hpiDFGUvf7mL96Mdi';
export const WSOL_TOKEN_MINT = 'So11111111111111111111111111111111111111112';

export const loadProgram = (connection: anchor.web3.Connection, wallet: AnchorWallet): anchor.Program<Game> => {
    const provider = new anchor.Provider(connection, wallet, {
        preflightCommitment: 'recent',
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const program: any = new anchor.Program(IDL as anchor.Idl, CONTRACT_ID, provider);
    return program;
};

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new anchor.web3.PublicKey(
    'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL'
);

export const getAtaForMint = async (
    mint: anchor.web3.PublicKey,
    buyer: anchor.web3.PublicKey
): Promise<[anchor.web3.PublicKey, number]> => {
    return await anchor.web3.PublicKey.findProgramAddress(
        [buyer.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID
    );
};

export const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

export const getMasterEditionAccount = async (mint: anchor.web3.PublicKey): Promise<anchor.web3.PublicKey> => {
    return (
        await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer(), Buffer.from('edition')],
            TOKEN_METADATA_PROGRAM_ID
        )
    )[0];
};

export const getMetadataAccount = async (mint: anchor.web3.PublicKey): Promise<anchor.web3.PublicKey> => {
    return (
        await anchor.web3.PublicKey.findProgramAddress(
            [Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer()],
            TOKEN_METADATA_PROGRAM_ID
        )
    )[0];
};

export const getAllPDAs = async (
    prog: anchor.Program<Game>,
    user: PublicKey = TOKEN_METADATA_PROGRAM_ID,
    nft: PublicKey = TOKEN_METADATA_PROGRAM_ID
) => {
    const [burnConfig, burnConfigBump] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from('config')],
        prog.programId
    );

    const [burnReceipt, burnReceiptBump] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from('receipt'), user.toBuffer(), nft.toBuffer()],
        prog.programId
    );

    const [nftData, nftDataBump] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from("toonie"), nft.toBuffer()],
        prog.programId
    );

    const [supply, supplyBump] = await anchor.web3.PublicKey.findProgramAddress(
        [Buffer.from('supply')],
        prog.programId
    );
    const userNftTokenAccount = await getAssociatedTokenAddress(nft, user);

    const nftTokenMetadataAccount = await getMetadataAccount(nft);

    return {
        burnConfig,
        burnConfigBump,
        burnReceipt,
        burnReceiptBump,
        userNftTokenAccount,
        nftTokenMetadataAccount,
        nftData,
        nftDataBump,
        supply,
    };
};

export const findTraitType = async (nftMint: PublicKey, connection: any) => {
    let traitType = 1000;
    let isOneOfOne = false;

    const metadata = await getMetadataFromTheMint(connection, nftMint.toString());
    console.log(`metadata`, metadata);

    let color, character;

    let attributes = metadata.fullMetadata.attributes;
    for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].trait_type === "Color") {
            color = attributes[i].value;
        }

        if (attributes[i].trait_type === "Character") {
            character = attributes[i].value;
        }
    }

    console.log(`color`, color, `character`, character);
    
    if (color === "OG") {
        if (character === "Heart") traitType = 0;
        if (character === "Sun") traitType = 1;
        if (character === "Bear") traitType = 2;
        if (character === "Flower") traitType = 3;
        if (character === "Alien") traitType = 4;
    } else if (color.includes("Mono")) {
        if (character === "Heart") traitType = 5;
        if (character === "Sun") traitType = 6;
        if (character === "Bear") traitType = 7;
        if (character === "Flower") traitType = 8;
        if (character === "Alien") traitType = 9;
    } else if (color === "Shadow") {
        if (character === "Heart") traitType = 10;
        if (character === "Sun") traitType = 11;
        if (character === "Bear") traitType = 12;
        if (character === "Flower") traitType = 13;
        if (character === "Alien") traitType = 14;
    } else {
        isOneOfOne = true;
    }

    return { traitType, isOneOfOne };
}

export const burnNft = async ({
    anchorWallet,
    values,
    connection,
    setBurnModalOpen,
}: {
    anchorWallet: AnchorWallet;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    connection: Connection;
    setBurnModalOpen: any;
}) => {
    if (!anchorWallet) {
        throw new Error('Wallet is not connected, please connect');
    }
    const anchorProgram = await loadProgram(connection, anchorWallet);

    const txn = new anchor.web3.Transaction();
    txn.add(PRIORITY_FEE_IX);

    const mint = new PublicKey(values.nftMint);

    const adminPk = new PublicKey("mvEpGLCXro2cfKKtSG6KK22KCk2caG9CsGmqcb3aR3n");
    const paymentPk = new PublicKey('DxhY682ui4ZzwTnK8xFfWbQaGxxSJM3FhTLCw6AdB7sZ');

    console.log(`values`, values);

    const { burnConfig, burnReceipt, userNftTokenAccount, nftTokenMetadataAccount, nftData, supply } = await getAllPDAs(
        anchorProgram,
        anchorWallet.publicKey,
        mint
    );

    console.log(`connection`, connection);

    let { traitType, isOneOfOne } = await findTraitType(mint, connection);
    console.log(traitType, isOneOfOne);

    if (traitType > 999 && !isOneOfOne) {
        throw new Error("No supply for this NFT!");
    }

    if (isOneOfOne) {
        traitType = 0;
    }

    txn.add(
        anchorProgram.instruction.burnNft(
            new anchor.BN(traitType),
            isOneOfOne,
            {
            accounts: {
                user: anchorWallet.publicKey,
                nftTokenMint: mint,
                nftData: nftData,
                burnReceipt: burnReceipt,
                burnConfig: burnConfig,
                nftTokenMetadata: nftTokenMetadataAccount,
                userNftTokenAddress: userNftTokenAccount,
                admin: adminPk,
                supply,
                solAccount: paymentPk,
                tokenProgram: TOKEN_PROGRAM_ID,
                nftProgramId: TOKEN_METADATA_PROGRAM_ID,
                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                systemProgram: anchor.web3.SystemProgram.programId,
                rent: SYSVAR_RENT_PUBKEY,
                time: SYSVAR_CLOCK_PUBKEY,
            },
        })
    );

    const lamports: number =
    await anchorProgram.provider.connection.getMinimumBalanceForRentExemption(
      MINT_SIZE
    );
    const newNftKeypair: anchor.web3.Keypair = anchor.web3.Keypair.generate();
    const newNftMint = newNftKeypair.publicKey;
    let walletKey = anchorWallet.publicKey;

    const newNftToken = (
        await anchor.web3.PublicKey.findProgramAddress(
          [
            anchorWallet.publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            newNftMint.toBuffer(),
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID
        )
      )[0];

      const metadataAddress = await getMetadataAccount(newNftMint);
    const masterEdition = await getMasterEditionAccount(newNftMint);

    const [newNftCreator, newNftCreatorBumpBump] =
    await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from("creator")],
      anchorProgram.programId
    );
    
    console.log(`anchor wallet`, anchorWallet);

    let mintSigner = new anchor.web3.PublicKey(anchorWallet.publicKey);

    txn.add(
        anchor.web3.SystemProgram.createAccount({
          fromPubkey: anchorWallet.publicKey,
          newAccountPubkey: newNftMint,
          space: MINT_SIZE,
          programId: TOKEN_PROGRAM_ID,
          lamports,
        }),
        createInitializeMintInstruction(
          newNftMint,
          0,
          walletKey, // mint authority
          walletKey, // freeze authority
        ),
        createAssociatedTokenAccountInstruction(
          walletKey,
          newNftToken,
          walletKey,
          newNftMint,
        ),
        createMintToInstruction(
          newNftMint,
          newNftToken,
          walletKey,
          1,
          [], // signer
        ),
    );


    const accts =  {
        // contractConfig: burnConfig,
        user: walletKey.toString(),
        burnReceipt: burnReceipt.toString(),
        nftMint: mint.toString(),
        nftData: nftData.toString(),
        toonieMint: newNftMint.toString(),
        toonieMetadata: metadataAddress.toString(),
        toonieMasterEdition: masterEdition.toString(),
        toonieCreator: newNftCreator.toString(),
        // admin: adminPk,
        tokenProgram: TOKEN_PROGRAM_ID.toString(),
        nftProgramId: TOKEN_METADATA_PROGRAM_ID.toString(),
        systemProgram: anchor.web3.SystemProgram.programId.toString(),
        rent: SYSVAR_RENT_PUBKEY,
        time: SYSVAR_CLOCK_PUBKEY,
      };
      console.log(`accts`, accts);

        txn.add(
        anchorProgram.instruction.mintNft({
        accounts: {
              // contractConfig: burnConfig,
              user: walletKey,
              burnReceipt: burnReceipt,
              nftMint: mint,
              nftData: nftData,
              toonieMint: newNftMint,
              toonieMetadata: metadataAddress,
              toonieMasterEdition: masterEdition,
              toonieCreator: newNftCreator,
              // admin: adminPk,
              tokenProgram: TOKEN_PROGRAM_ID,
              nftProgramId: TOKEN_METADATA_PROGRAM_ID,
              systemProgram: anchor.web3.SystemProgram.programId,
              rent: SYSVAR_RENT_PUBKEY,
              time: SYSVAR_CLOCK_PUBKEY,
            },
            signers: [newNftKeypair],
        }));

        console.log(`txn`, txn);

        let { blockhash } = await fastConnection.getLatestBlockhash();
        console.log(`blockhash`, blockhash);
        txn.recentBlockhash = blockhash;
        txn.feePayer = walletKey;
        console.log(txn);
        txn.sign(newNftKeypair);

        const signedTxs = await anchorWallet?.signAllTransactions([txn]);
        console.log(`signed`, signedTxs);

        setBurnModalOpen(true);
        for (let sig in signedTxs) {
            console.log(`confirming tx ${sig}`);
            const sentTx = await anchor.web3.sendAndConfirmRawTransaction(fastConnection, signedTxs[sig as any].serialize(), { commitment: 'finalized', maxRetries: 200, skipPreflight: true });
            console.log(`sent tx`, sentTx);
            // console.log(`signed txs`, signedTxs[sig]);
        }
        // await sendAndConfirmTransactionListCustom1(anchorWallet, connection, [txn])

};
