import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useFormikContext } from "formik";
import * as React from "react";

export const DatePickerType1 = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, setFieldValue }: any = useFormikContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box mt={2}>
        <DateTimePicker
          label={label}
          value={values[name]}
          onChange={(newDate) => setFieldValue(name, newDate)}
          renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
            <TextField {...params} fullWidth />
          )}
          ampm={false}
        />
      </Box>
    </LocalizationProvider>
  );
};

export const StringInputType1 = ({
  name,
  label,
  type = "number",
}: {
  name: string;
  label: string;
  type?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, touched, errors, handleChange }: any = useFormikContext();
  return (
    <Box mt={2}>
      <TextField
        label={label}
        name={name}
        value={values[name]}
        variant="outlined"
        onChange={handleChange}
        fullWidth
        maxRows={4}
        minRows={4}
        error={Boolean(touched[name] && errors[name])}
        helperText={touched[name] && errors[name]}
        type={type}
        size="small"
      />
    </Box>
  );
};

export const SubmitButtonType1 = ({ label }: { label: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isSubmitting }: any = useFormikContext();
  return (
    <Box
      my={2}
      style={{
        position: "relative",
      }}
    >
      <Button
        color="primary"
        disabled={isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        className="mintBtn"
      >
        {label}
      </Button>
      {isSubmitting && (
        <CircularProgress
          size={24}
          style={{
            color: "green",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </Box>
  );
};

export const DropDownInputType1 = ({
  name,
  label,
  options,
  labelId = "network-select-label",
}: {
  name: string;
  label: string;
  options: string[];
  labelId?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, handleChange, touched, errors }: any = useFormikContext();
  return (
    <Box mt={2}>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          label={label}
          name={name}
          value={values[name]}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          error={Boolean(touched[name] && errors[name])}
        >
          {options.map((oneOption) => (
            <MenuItem value={oneOption} id={oneOption} key={oneOption}>
              {oneOption}
            </MenuItem>
          ))}
        </Select>
        {touched[name] && errors[name] && (
          <FormHelperText error>{touched[name] && errors[name]}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export const CountryDropDownInputType = ({
  name,
  label,
  options,
  labelId = "network-select-label",
}: {
  name: string;
  label: string;
  options: {
    Code: string;
    Name: string;
  }[];
  labelId?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, handleChange, touched, errors }: any = useFormikContext();
  return (
    <Box mt={2}>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          label={label}
          name={name}
          value={values[name]}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          size="small"
          error={Boolean(touched[name] && errors[name])}
        >
          {options.map((oneOption) => (
            <MenuItem value={oneOption.Code} id={oneOption.Code} key={oneOption.Code}>
              {oneOption.Name}
            </MenuItem>
          ))}
        </Select>
        {touched[name] && errors[name] && (
          <FormHelperText error>{touched[name] && errors[name]}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};