import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { fastConnection } from "solanacodes/config";
import { burnNft, loadProgram } from "solanacodes/game/game";
import { getAllNFTsGlobal } from "./NFT";

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

export interface NftType {
  code: number;
  mint: string;
  src: string;
  name: string;
}

export interface StakingStore {
  setIsLoading: (isLoading: boolean) => void;
  isLoading: boolean;
  solanaTimeDifference: number;
  blockTimeInEpoch: number;
  allNfts: NftType[];
  burnedNfts: NftType[];
  allBurned: number;
  onClickHandleBurn: (mintAddress: string, setBurnModalOpen) => Promise<boolean>;
  getAllNfts: () => Promise<void>;
  justBurnedNft: string;
  setJustBurnedNft: (mintAddress: string) => void;
  supplyList: any[];
  myBurned: number;
}

//@ts-ignore
export const ContractStoreContext = createContext<StakingStore>();

export const ContractStoreProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const connection = fastConnection;
  const wallet: any = useAnchorWallet();

  const [allNfts, setAllNfts] = useState([]);
  const [burnedNfts, setBurnedNfts] = useState([]);
  const [allBurned, setAllBurned] = useState(0);
  const [myBurned, setMyBurned] = useState(0);
  const [supplyList, setSupplyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blockTimeInEpoch, setBlockTimeInEpoch] = useState<number>(
    Date.now() / 1000
  );
  const [justBurnedNft, setJustBurnedNft] = useState<string>("");
  const [isNFTsGettingFetched, setIsNFTsGettingFetched] = useState(false);

  const fetchBlockTimeInEpoch = useCallback(async () => {
    const epochInfo = await connection.getEpochInfo();
    const blockTimeInEpochLocal = await connection.getBlockTime(
      epochInfo.absoluteSlot
    );
    setBlockTimeInEpoch(blockTimeInEpochLocal);
  }, [connection]);

  useEffect(() => {
    fetchBlockTimeInEpoch();
  }, [fetchBlockTimeInEpoch]);

  const solanaTimeDifference = useMemo(() => {
    const now = Date.now() / 1000;
    return now - blockTimeInEpoch;
  }, [blockTimeInEpoch]);

  const [firstFetchDone, setFirstFetchDone] = useState(false);

  const getAllNfts = React.useCallback(async () => {
    if (isNFTsGettingFetched) {
      console.log("nfts are already getting fetched");
      return;
    }
    try {
      setIsNFTsGettingFetched(true);
      const nftInfoList = await getAllNFTsGlobal(wallet, connection, false);

      const program = await loadProgram(connection, wallet);
      // get all the burn receipts
      const pdaListAll = await program.account.burnReceipt.all();
      setAllBurned(pdaListAll.length);

      const supplyListAll = await program.account.supply.all();

      setSupplyList(supplyListAll[0].account.figureSupply);

      const offset = 8; //need to prepend 8 bytes for anchor's disc
      const pdaList = await program.account.burnReceipt.all([
        {
          memcmp: {
            offset,
            bytes: wallet.publicKey.toBase58(),
          },
        },
      ]);
      console.log(`pdaList`, pdaList);

      console.log(`Found ${pdaList.length} receipts`);
      setMyBurned(pdaList.length);
      const burnedNftMintStrList = pdaList.map((pda) =>
        pda.account.mint.toBase58()
      );
      console.log(`strlist`, burnedNftMintStrList);
      const burnedNftInfoList = await getAllNFTsGlobal(
        wallet,
        connection,
        true,
        burnedNftMintStrList
      );

      console.log(`nftinfo`, burnedNftInfoList);

      //sort both arrays
      nftInfoList.sort(
        (a: { code: number }, b: { code: number }) => a.code - b.code
      );
      burnedNftInfoList.sort(
        (a: { code: number }, b: { code: number }) => a.code - b.code
      );

      console.log(`test`, { nftInfoList, burnedNftInfoList });

      setAllNfts(nftInfoList);
      setBurnedNfts(burnedNftInfoList);
      
      // refresh staking state as well
      // await fetchStakingState();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsNFTsGettingFetched(false);
      console.log("fetching nfts finished");
    }
  }, [connection, wallet, isNFTsGettingFetched]);

  const firstFetch = useCallback(async () => {
    if (wallet) {
      if (firstFetchDone) {
        return;
      }
      let loadingSnackbar = undefined;
      try {
        if (!isLoading) {
          loadingSnackbar = enqueueSnackbar("Loading your NFTs...", {
            variant: "info",
            persist: true,
          });
          setIsLoading(true);
          setFirstFetchDone(true);
          await getAllNfts();
          setIsLoading(false);
        }
      } catch (error) {
        console.log({ error });
      } finally {
        if (loadingSnackbar) {
          closeSnackbar(loadingSnackbar);
        }
      }
      setIsLoading(false);
    }
  }, [wallet, firstFetchDone, isLoading, enqueueSnackbar, getAllNfts, closeSnackbar]);

  useEffect(() => {
    firstFetch();
  }, [firstFetch]);


  const assertWalletConnected = useCallback(() => {
    if (!wallet) {
      enqueueSnackbar("Please connect your wallet!", { variant: "error" });
      return false;
    }
    return true;
  }, [wallet]);

  const onClickHandleBurn = async (mintAddress: string, setBurnModalOpen) => {
    let successResult = false;
    if (assertWalletConnected()) {
      let persistentSnackbar = undefined;
      try {
        await burnNft({
          anchorWallet: wallet,
          values: { nftMint: mintAddress },
          connection: fastConnection,
          setBurnModalOpen,
        });
        setBurnModalOpen(false);

        await getAllNfts();
        enqueueSnackbar("Done", {
          variant: "success",
        });
        setJustBurnedNft(mintAddress);
        successResult = true;
      } catch (error) {
        handleError(error);
      } finally {
        setBurnModalOpen(false);
        if (persistentSnackbar) closeSnackbar(persistentSnackbar);
      }
    }
    return successResult;
  };

  const handleError = (error: any) => {
    const errorString = error.toString();

    console.log({ error, errorString });

    const NETWORK_ERROR =
      "The transaction did not confirm, please reload the page and try again!";

    // Error 1
    const blockHashError = "Transaction simulation failed: Blockhash not found";
    if (errorString.includes(blockHashError)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 2
    const errorTransactionTimeout = "Error: Transaction was not confirmed in ";
    if (errorString.includes(errorTransactionTimeout)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 3
    const nodeIsBehind = "Error: failed to send transaction: Node is behind by";
    if (errorString.includes(nodeIsBehind)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 4
    const errorProcessingInstruction =
      "Transaction simulation failed: Error processing Instruction 0: invalid account data for instruction";
    if (errorString.includes(errorProcessingInstruction)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 5
    const error469 = "Error: 469 undefined:";
    if (errorString.includes(error469)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 6
    const error429 = "Error: 429 Too Many Requests";
    if (errorString.includes(error429)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 7
    const errorSocketHangUp = "failed, reason: socket hang up";
    if (errorString.includes(errorSocketHangUp)) {
      enqueueSnackbar(NETWORK_ERROR, {
        variant: "error",
      });
      return;
    }

    // Error 8
    if (errorString.includes("0x1781")) {
      enqueueSnackbar("Insufficient Token Balance", {
        variant: "error",
      });
      return;
    }

    // Default handler
    enqueueSnackbar(errorString, {
      variant: "error",
    });
  };

  return (
    <ContractStoreContext.Provider
      value={{
        setIsLoading,
        isLoading,
        solanaTimeDifference,
        blockTimeInEpoch,
        allNfts,
        burnedNfts,
        getAllNfts,
        allBurned,
        onClickHandleBurn,
        justBurnedNft,
        setJustBurnedNft,
        supplyList,
        myBurned,
      }}
    >
      {children}
    </ContractStoreContext.Provider>
  );
};

export const useContractStore = () => {
  const context = useContext(ContractStoreContext);
  if (context === undefined) {
    throw new Error(
      "useContractStore must be used within a ContractStoreProvider"
    );
  }
  return context;
};
