import React from 'react';
import { Container, Row, Col, List } from 'bootstrap-4-react';
import classNames from 'classnames';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Link } from "react-router-dom";
import { useContractStore } from 'contexts/ContractStoreProvider';

const Header = () => {
  const { getAllNfts, setIsLoading } = useContractStore();

  const handleLogoClick = async() => {
    setIsLoading(true);
    await getAllNfts();
    setIsLoading(false);
  }

  return (
    <header className="header">
      <div className="header-bar">
        BURN WINDOW IS CLOSED
      </div>
      <div className="header-container">
        <div className="header-center">
          
          <a href='#' onClick={handleLogoClick}>
          <img
            src='/toonies_logo.png'
            alt="toonies"
          />
          </a>
        </div>
        <div className="header-right">
          <a href="https://twitter.com/toonies_art" target="_blank" rel="noreferrer">
            <img className="icon-twitter" src="/Twitter_icon.svg" />
          </a>
          <a href="https://discord.gg/83CZC2FbGt" target="_blank" rel="noreferrer">
            <img className="icon-discord" src="/Discord_icon.png" />
          </a>
          <WalletMultiButton />
        </div>
      </div>
    </header>
  );
}

export default Header;
