import { Grid, Typography, Box, Button } from '@mui/material';
import { BurnedNftCard } from 'components/Cards/BurnedNftCard';
import { NftCard } from 'components/Cards/NftCard';
import { useContractStore } from 'contexts/ContractStoreProvider';
import React, { useEffect } from 'react';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import './components/Cards/style.css';
import SwapCardsBurn from './components/SectionStory/SwapCardsBurn';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';

function BurnAndAddress() {
  const { allNfts, burnedNfts, justBurnedNft, isLoading, allBurned, supplyList, myBurned } = useContractStore();
  const { connected } = useWallet();
  const [showTab, setShowtab] = React.useState('To burn');
  const [value, setValue] = React.useState(0);

  useEffect(
    () => {
      if (justBurnedNft) {
        setShowtab('Burned');
      }
    }, [justBurnedNft]
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const showTypeTab = () => {
    if (showTab !== 'To burn') {
      return (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              m: 2,
            }}
            className='gridLayout'
          >
            {burnedNfts.map((nft) => {
              return (
                <Grid item xs={6} sm={6} md={4} lg={2} key={nft.mint}>
                  <BurnedNftCard
                    name={nft.name}
                    image={nft.src}
                    mint={nft.mint}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              m: 2,
            }}
            className='gridLayout'
          >
            {allNfts.map((nft) => {
              return (
                <Grid item xs={6} sm={6} md={4} lg={2} key={nft.mint}>
                  <NftCard nft={nft} name={nft.name} image={nft.src} mint={nft.mint} />
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    }
  };
  if (!connected) {
    return (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h2 className="subtitle">
            a Figure-Backed Mystery Pack
          </h2>
          <h1 className="title title-welcome">
            Welcome to Toonies Burn
          </h1>
          <div className="cta-connect">
            <WalletMultiButton />
          </div>
          <div className="tickets-wrap">
            <div className="tickets">
              <div className="ticket">
                <div className="ticket-number">1</div>
                <div className="ticket-badge">May 6 - May 10, 2024</div>
                <div className="ticket-icon">
                  <img src="/fire.png" />
                </div>
                <div className="ticket-title">
                  Burn Toonie NFT
                </div>
                <div className="ticket-content">
                  Burn your Toonies NFT + 0.2 sol to redeem your matching physical figure and merch pack.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket-number">2</div>
                <div className="ticket-icon">
                  <img src="/form.png" />
                </div>
                <div className="ticket-title">
                  Enter Shipping Address
                </div>
                <div className="ticket-content">
                  For every Toonies NFT you burn, you can enter a shipping address for your figure and merch pack to be delivered to.
                </div>
              </div>
              <div className="ticket">
                <div className="ticket-number">3</div>
                {/* <div className="ticket-badge">Sep 26, 2022</div> */}
                <div className="ticket-icon">
                  <img src="/vibe.png" />
                </div>
                <div className="ticket-title">
                  Receive your proof of ownership NFT
                </div>
                <div className="ticket-content">
                  Verify your physical goods and stay within the Toonies community with your new Toonies Token NFT.
                </div>
              </div>
            </div>
          </div>
        </Box>
        <Footer />
      </>
    );

  }
  if (!allNfts || !burnedNfts || isLoading) {
    return (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h1 className="title title-loading">Searching for NFTs</h1>
        </Box>
      </>
    );
  }
  console.log(`all nfts`, allNfts);
  // if (allNfts.length == 0 && burnedNfts.length == 0) {
  //   return (
  //     <>
  //       <Header />
  //       <Box
  //         display='flex'
  //         alignItems='center'
  //         flexDirection='column'
  //         paddingLeft='20px'
  //         paddingRight='20px'
  //       >
  //         <h2 className="subtitle">
  //           a Figure-Backed Mystery Pack
  //         </h2>
  //         <h1 className="title">
  //           You have no Toonies to redeem.
  //         </h1>
  //         <a className="cta" href="https://magiceden.io/marketplace/toonies">
  //           Buy on Magic Eden
  //         </a>
  //       </Box>
  //     </>
  //   );
  // }
  return (
    (allNfts.length || burnedNfts.length || !isLoading) && (
      <>
        <Header />
        <Box
          display='flex'
          alignItems='center'
          flexDirection='column'
          paddingLeft='20px'
          paddingRight='20px'
        >
          <h2 className="subtitle">
            Burn your Toonies NFT for your figure and merch pack!
          </h2>
          <div className="paragraph">
            You have burned <div><span>{myBurned}</span></div> Toonies NFTs.
          </div>
          {/* <div className="paragraph">
            You have no Toonies to redeem.
          </div> */}
          <div className="paragraph">
            <div><span>{allBurned}</span></div>Toonies have been burned so far.
          </div>
          <br/>

          <h2 className="subtitle">
            Figure supplies left
          </h2>
          <Grid container justifyContent="center" spacing={8} mb={4}>
            <Grid item>
              <div className="supply">
                <div><span>{supplyList[0]?.toNumber() + ' '}</span>OG Heart</div>
                <div><span>{supplyList[1]?.toNumber() + ' '}</span>OG Sun</div>
                <div><span>{supplyList[2]?.toNumber() + ' '}</span>OG Bear</div>
                <div><span>{supplyList[3]?.toNumber() + ' '}</span>OG Flower</div>
                <div><span>{supplyList[4]?.toNumber() + ' '}</span>OG Alien</div>
              </div>
            </Grid>

            <Grid item>
              <div className="supply">
                <div><span>{supplyList[5]?.toNumber() + ' '}</span>Mono Heart</div>
                <div><span>{supplyList[6]?.toNumber() + ' '}</span>Mono Sun</div>
                <div><span>{supplyList[7]?.toNumber() + ' '}</span>Mono Bear</div>
                <div><span>{supplyList[8]?.toNumber() + ' '}</span>Mono Flower</div>
                <div><span>{supplyList[9]?.toNumber() + ' '}</span>Mono Alien</div>
              </div>
            </Grid>

            <Grid item>
              <div className="supply">
                <div><span>{supplyList[10]?.toNumber() + ' '}</span>Shadow Heart</div>
                <div><span>{supplyList[11]?.toNumber() + ' '}</span>Shadow Sun</div>
                <div><span>{supplyList[12]?.toNumber() + ' '}</span>Shadow Bear</div>
                <div><span>{supplyList[13]?.toNumber() + ' '}</span>Shadow Flower</div>
                <div><span>{supplyList[14]?.toNumber() + ' '}</span>Shadow Alien</div>
              </div>
            </Grid>
          </Grid>
         
        </Box>
        <SwapCardsBurn />
        <br />
        <div
          className='headerText'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '50%' }}
          >
            <Button
              variant='text'
              className={
                showTab == 'To burn' ? 'headerBtn active' : 'headerBtn'
              }
              onClick={() => setShowtab('To burn')}
            >
              Toonies Held
            </Button>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '50%' }}
          >
            <Button
              variant='text'
              className={
                showTab !== 'To burn' ? ' headerBtn active' : 'headerBtn'
              }
              onClick={() => setShowtab('Burned')}
            >
              Toonies Burned
            </Button>
          </div>
        </div>
        {showTypeTab()}
      </>
    )
  );
}
export default BurnAndAddress;
